import { getCvPartner } from "Cvpartner";
import { CurrentUser } from "stores/CurrentUser";
import { prefetchLoggedInStatus } from "stores/LoggedInStatus";
import type { CVPartnerReactRoute } from "types";
import { renderRouter } from "util/react";
import { NotFound } from "views/errors/404";
import { RouteError } from "views/errors/500";

export class FallbackRouter implements CVPartnerReactRoute {
  render() {
    return renderRouter(
      [
        {
          path: "*",
          element: <NotFound />,
          errorElement: <RouteError />,
          action: async () => {
            if (getCvPartner().isLoggedIn) {
              CurrentUser.reload_if_not_loaded();
            }
            await prefetchLoggedInStatus();

            return null;
          },
        },
      ],
      "main",
    );
  }
}
